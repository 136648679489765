.TrackerMenu {
    position: fixed;
    width: 300px;
    overflow-y: hidden;
    overflow-x: hidden;
}

.TrackerMenuBtn {
    width: 50px;
    height: 50px;
    text-align: center;
    position: fixed;
    top: 5px;
    right: 5px;
    cursor: pointer;
    user-select: none;
    /* border: 2px solid; */
    /* border-radius: 50%; */
    /* background-color: rgba(0, 0, 0, .5); */
}

.TrackerMenuBtn span {
    font-size: 40px;
    margin-top: 5px;
    /* -webkit-animation: rotate-center .5s ease-in-out both;
    animation: rotate-center .5s ease-in-out both; */
}

.TrackerMenuContent {
    display: block;
    border-bottom-left-radius: 5px;
    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ::-webkit-scrollbar {
    width: 20;
    background: transparent;
} */

.TrackerMenuThemes {
    display: flex;
    flex-wrap: wrap;
    padding: 3px;
}

/* .TrackerMenuThemes {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.TrackerMenuThemes::-webkit-scrollbar {
    display: none;
} */

.TrackerMenuTheme {
    height: 90px;
    border-radius: 3px;
    margin: 2px;
    cursor: pointer;
    user-select: none;
    width: calc(33.33% - 4px);
}

.TrackerMenuThemeChecked{
    opacity: .65;
    border-bottom: 2px solid #00ff3c;
}

.TrackerMenuTheme img {
    display: block;
    width: 60px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    border-radius: 50%;
}

.TrackerMenuTheme p {
    text-align: center;
    font-size: 14px;
}

.TrackerMenuMapTypeWrapper {
    display: flex;
    justify-content: space-evenly;
    margin-top: -4px;
    margin-bottom: 2px;
    padding: 3px;
}

.TerrainToggle, .RoadMapToggle, .SnowToggle {
    width: calc(33.33% - 4px);
    margin-left: 2px;
    margin-right: 2px;
}

.TerrainToggle p, .RoadMapToggle p, .SnowToggle p {
    font-size: 14px;
}

#snow-toggled-on{
    opacity: .65;
}

#terrain-toggled-off, #terrain-toggled-on, #snow-toggled-on, #snow-toggled-off, #roadmap-toggled-on, #roadmap-toggled-off {
    display: flex;
    height: 35px;
    border: none;
}

#terrain-toggled-off:focus, #terrain-toggled-on:focus, #snow-toggled-on:focus, #snow-toggled-off:focus, #roadmap-toggled-on:focus, #roadmap-toggled-off:focus {
    outline: none;
    box-shadow: none;
}

#terrain-toggled-on, #snow-toggled-on {
    border-bottom: solid 2px #00ff3c;
}

#roadmap-toggled-on, #roadmap-toggled-on {
    border-bottom: solid 2px #00ff3c;
}

#terrain-toggled-off p, #terrain-toggled-on p, #snow-toggled-off p, #snow-toggled-on p, #roadmap-toggled-on p, #roadmap-toggled-off p {
    margin-left: 5px;
}

#terrain-toggled-on span, #terrain-toggled-off span {
    margin-left: -2px;
}

#snow-toggled-on span, #snow-toggled-off span {
    margin-left: 2px;
}

.TrackerMenuSantaData {
    width: calc(100% - 8px);
    justify-content: left;
    margin-top: -1px;
    margin-bottom: 4px;
    margin-left: 4px;
    border-radius: 3px;
}

.TrackerMenuSantaDataItem {
    display: flex;
    height: 35px;
    line-height: 35px;
    margin-right: 2px;
    padding-left: 5px;
    padding-right: 5px;
}

.TrackerMenuSantaDataItem span {
    font-size: 27px;
    margin-top: 5px;
    margin-right: 5px;
}

.TrackerMenuSantaDataStandard{
    background-color: #bdd8bb;
}

.TrackerMenuSantaDataRetro{
    background-color: #dfd2ae;
}

.TrackerMenuSantaDataSilver{
    background-color: #999998;
}

.TrackerMenuSantaDataDark{
    background-color: #434343;
}

.TrackerMenuSantaDataNight{
    background-color: #4d5662;
}

.TrackerMenuSantaDataAubergine{
    background-color: #2d5f75;
}

.TrackerMenuSantaDataStandard span, .TrackerMenuSantaDataStandard p{
    color: #000000;
}

.TrackerMenuSantaDataRetro span, .TrackerMenuSantaDataRetro p{
    color: #000000;
}

.TrackerMenuSantaDataSilver span, .TrackerMenuSantaDataSilver p{
    color: #000000;
}

.TrackerMenuSantaDataDark span, .TrackerMenuSantaDataDark p{
    color: #b5b5b5;
}

.TrackerMenuSantaDataNight span, .TrackerMenuSantaDataNight p{
    color: #ffffff;
}

.TrackerMenuSantaDataAubergine span, .TrackerMenuSantaDataAubergine p{
    color: #ffffff;
}

#tracker-menu-location-btn-allowed-silver span{
    color: #28a745;
}

.TrackerMenuFooter {
    display: flex;
    margin-left: 4px;
    margin-right: 4px;
    padding-bottom: 2px;
}

.TrackerMenuFooter span {
    font-size: 40px !important;
    margin-top: 5px;
}

.TrackerMenuRoutes {
    position: absolute;
    width: 100%;
    bottom: -18px;
}

.TrackerMenuFooterBtn, .TrackerMenuCloseBtn {
    cursor: pointer;
    user-select: none;
    width: 50px;
    height: 50px;
    border-radius: 3px;
    text-align: center;
    margin-right: 3px;
    margin-bottom: 3px;
}

.TrackerMenuFooterBtn-standard {
    background-color: #bdd8bb;
}

.TrackerMenuFooterBtn-retro {
    background-color: #dfd2ae;
}

.TrackerMenuFooterBtn-silver {
    background-color: #737372ba;
}

.TrackerMenuFooterBtn-dark {
    background-color: #434343;
}

.TrackerMenuFooterBtn-night {
    background-color: #4c5561;
}

.TrackerMenuFooterBtn-aubergine {
    background-color: #2c5e74;
}

#tracker-menu-compass-btn-aubergine span, #tracker-menu-compass-btn-night span {
    color: #FFFFFF;
}

#tracker-menu-compass-btn-dark span {
    color: #b4b4b4;
}

.TrackerMenuCloseBtn {
    margin-left: auto;
    margin-right: 0px;
}

#tracker-menu-location-btn-allowed-night {
    color: #28a745;
    background-color: #4c5561
}

#tracker-menu-location-btn-denied-night {
    color: #dc3545;
    background-color: #4c5561
}

#tracker-menu-location-btn-allowed-aubergine {
    color: #28a745;
    background-color: #2c5e74;
}

#tracker-menu-location-btn-denied-aubergine {
    color: #dc3545;
    background-color: #2c5e74;
}

#tracker-menu-location-btn-allowed-retro {
    color: #28a745;
    background-color: #ded1ad;
}

#tracker-menu-location-btn-denied-retro {
    color: #dc3545;
    background-color: #ded1ad;
}

#tracker-menu-location-btn-allowed-silver {
    color: #28a745;
    background-color: #737372ba;
}

#tracker-menu-location-btn-denied-silver {
    color: #dc3545;
    background-color: #737372ba;
}

#tracker-menu-location-btn-allowed-dark {
    color: #b5b5b5;
    background-color: #434343;
}

#tracker-menu-location-btn-denied-dark {
    color: #dc3545;
    background-color: #434343;
}

#tracker-menu-location-btn-allowed-standard {
    color: #28a745;
}

#tracker-menu-location-btn-denied-standard {
    color: #dc3545
}

#tracker-menu-close-btn-night {
    color: #ffffff;
}

#tracker-menu-close-btn-dark {
    color: #b5b5b5;
}

#tracker-menu-close-btn-aubergine {
    color: #FFFFFF;
}

#tracker-menu-close-btn-silver {
    color: #000000;
}

#tracker-menu-donate-btn-standard span {
    color: #000000;
}

#tracker-menu-donate-btn-retro span {
    color: #000000;
}

#tracker-menu-donate-btn-silver span {
    color: #ffffff;
}

#tracker-menu-donate-btn-dark span {
    color: #b5b5b5;
}

#tracker-menu-donate-btn-night span {
    color: #ffffff;
}

#tracker-menu-donate-btn-aubergine span {
    color: #ffffff;
}

#tracker-menu-home-btn-standard {
    color: #000000;
}

#tracker-menu-home-btn-retro {
    color: #000000;
}

#tracker-menu-home-btn-silver {
    color: #000000;
}

#tracker-menu-home-btn-dark {
    color: #b5b5b5;
}

#tracker-menu-home-btn-night {
    color: #ffffff;
}

#tracker-menu-home-btn-aubergine {
    color: #ffffff;
}

#standard-theme-menu-btn {
    background-color: rgba(255, 255, 255, 1);
    color: #666666;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 30%);
    border-radius: 8px;
}

#standard-theme-menu-content {
    background-color: #ffffffcf;
}

#standard-theme-menu-content .TrackerMenuTheme {
    background-color: #bdd8bb;
}

#standard-theme-menu-content button {
    background-color: #bdd8bb;
    color: black;
}

#retro-theme-menu-btn {
    background-color: #dfd2ae;
    color: #666666;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 30%);
    border-radius: 8px;
}

#retro-theme-menu-content {
    background-color: #646f37d1;
}

#retro-theme-menu-content .TrackerMenuTheme {
    background-color: #dfd2ae;
}

#retro-theme-menu-content button {
    background-color: #dfd2ae;
    color: black;
}

#silver-theme-menu-btn {
    background-color: #747473;
    color: #f7f7f7;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 30%);
    border-radius: 8px;
}

#silver-theme-menu-content {
    background-color: #ffffff;
    border: 1px solid #737372ba;
}

#silver-theme-menu-content .TrackerMenuTheme {
    background-color: #737372ba;
}

#silver-theme-menu-content button {
    background-color: #737372ba;
    color: black;
}

#dark-theme-menu-btn {
    background-color: #ffffff1c;
    color: #929292;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 30%);
    border-radius: 8px;
}

#dark-theme-menu-content {
    background-color: #2f2d2de6;
}

#dark-theme-menu-content .TrackerMenuTheme {
    background-color: #434343;
    color: #b5b5b5;
}

#dark-theme-menu-content button {
    background-color: #434343;
    color: #b5b5b5;
}

#night-theme-menu-btn {
    background-color: rgba(0, 0, 0, .5);
    color: #666666;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 30%);
    border-radius: 8px;
}

#night-theme-menu-content {
    background-color: rgba(0, 0, 0, .5);
}

#night-theme-menu-content .TrackerMenuTheme {
    background-color: #4d5662;
    color: #FFFFFF;
}

#night-theme-menu-content button {
    background-color: #4d5662;
    color: #FFFFFF;
}

#aubergine-theme-menu-btn {
    color: #929292;
    background-color: #21556cad;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 30%);
    border-radius: 8px;
}

#aubergine-theme-menu-content {
    background-color: #1d2c4dd4;
}

#aubergine-theme-menu-content .TrackerMenuTheme {
    background-color: #2d5f75;
    color: #FFFFFF;
}

#aubergine-theme-menu-content button {
    background-color: #2d5f75;
}

.TrackerMenuSantaDatItem {
    display: flex;
    /* align-items: center;
    justify-content: center; */
}

.TrackerMenuSantaDatItem p {
    font-size: 14px;
}

.TrackerMenuSantaDatItem span, .TrackerMenuSantaDatItem p {
    margin: 0px;
    padding: 0px
}

.TrackerMenuSantaDatItem span {
    margin-right: 10px;
    margin-left: 10px;
}

#tracker-menu-santa-dat-wrapper-standard {
    /* background-color: #bdd8bb; */
    color: #000000;
}

#tracker-menu-santa-dat-wrapper-standard span {
    color: #000000;
}

#tracker-menu-santa-dat-wrapper-retro {
    /* background-color: #dfd2ae; */
    color: #000000;
}

#tracker-menu-santa-dat-wrapper-retro span {
    color: #000000;
}

#tracker-menu-santa-dat-wrapper-silver {
    /* background-color: #737373; */
    color: #ffffff;
}

#tracker-menu-santa-dat-wrapper-silver span {
    color: #ffffff;
}

#tracker-menu-santa-dat-wrapper-dark {
    /* background-color: #434343; */
    color: #b5b5b5;
}

#tracker-menu-santa-dat-wrapper-dark span {
    color: #b5b5b5;
}

#tracker-menu-santa-dat-wrapper-night {
    color: #ffffff;
}

#tracker-menu-santa-dat-wrapper-night span {
    color: #ffffff;
}

#tracker-menu-santa-dat-wrapper-aubergine {
    /* background-color: #2d5f75; */
    color: #ffffff;
}

#tracker-menu-santa-dat-wrapper-aubergine span {
    color: #ffffff;
}

#distance-from-user-to-santa-menu-pill-wrapper {
    height: 28px;
    display: flex;
    width: calc(50% - 1px);
}

#distance-from-user-to-santa-menu-pill-wrapper p {
    font-size: 17px;
    color: #FFFFFF;
    float: left;
    white-space: nowrap;
    font-weight: 600;
}

.DistanceFromUserToSantaTrackerMenu span {
    margin-left: 13px;
    margin-right: 8px;
}

#santa-hat-menu-user-loc {
    height: 28px;
    margin-left: 10px;
}

#distance-from-user-to-santa-tracker-menu-standard p {
    color: #000000;
}

#distance-from-user-to-santa-tracker-menu-retro p {
    color: #000000;
}

#distance-from-user-to-santa-tracker-menu-silver p {
    color: #ffffff;
}

#distance-from-user-to-santa-tracker-menu-dark p {
    color: #b5b5b5;
}

#distance-from-user-to-santa-tracker-menu-dark span {
    color: #b5b5b5;
}

#distance-from-user-to-santa-tracker-menu-night p {
    color: #FFFFFF;
}

#distance-from-user-to-santa-tracker-menu-night span {
    color: #FFFFFF;
}

#distance-from-user-to-santa-tracker-menu-aubergine p {
    color: #FFFFFF;
}

#distance-from-user-to-santa-tracker-menu-aubergine span {
    color: #FFFFFF;
}