.EndShow {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-image: url("https://i.ibb.co/SX7ZGFD/pre-Show-BG.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    user-select: none;
    overflow-x: hidden;
    overflow-y: hidden;
}

#endshow-modal .modal-content {
    background-color: #19263c;

}

#endshow-modal .modal-header, #endshow-modal .modal-body, #endshow-modal .modal-footer {
    margin: 0px;
    padding: 0px;
}

.EndShowModalHeader {
    width: 100%;
}

.EndShowModalHeader p {
    margin: 0px;
    padding: 0px;
    text-align: center;
}

.EndShowModalHeaderP1 {
    font-size: 25px;
}

.EndShowModalHeaderP2 {
    font-size: 20px;
}

.EndShowModalBody {
    display: flex;
    justify-content: center;
    height: 153px;
    padding-left: 10px;
    padding-right: 10px;
}

.EndShowModalBody img {
    max-width: 100%;
    margin: 5px;
}

.EndShowModalBodyLaughingSantaWrapper {
    position: absolute;
    left: 120px;
    transform: scale(.8);
}

.EndShowModalFooter {
    height: 50px;
}

#endshow-home-btn {
    position: absolute;
    right: 5px;
    bottom: 4px;
    cursor: pointer;
    user-select: none;
    width: 50px;
    height: 50px;
    border-radius: 8px;
    text-align: center;
    background-color: #4c5561;
    color: #FFFFFF;
    padding-top: 5px;
    font-size: 40px !important;
}

#endshow-waiting-for-images {
    position: absolute;
    right: 5px;
    bottom: 5px;
    color: #666666;
    width: 30px;
    height: 30px;
}

#end-show-sponsor-link {
    position: absolute;
    left: 5px;
    bottom: 0px;
}

#end-show-sponsor-link p {
    cursor: pointer;
    user-select: none;
}