.TrackerMenu {
  position: absolute;
  top: 0px;
  right: 0px;
  color: black;
  z-index: 10;
}

.TrackerCustomLabel img {
  height: 20px;
}

.TopLeftInfoWrapper {
  position: fixed;
  top: 5px;
  left: 5px;
}

.DistanceFromUserToSanta {
  border-radius: 10px;
  margin-bottom: 5px;
}

#stats-item-wrapper {
  width: 100%;
}

#stats-item-wrapper p {
  margin: 0px;
  padding: 0px;
  margin-left: 5px;
  /* text-align: center; */
}

.DistanceFromUserToSanta {
  height: 35px;
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

#distance-from-user-to-santa-content-wrapper {
  display: flex;
}

#distance-from-user-to-santa-content-wrapper p {
  margin-top: 5px;
  margin-right: 8px;
  margin-left: 3px;
  user-select: none;
  font-weight: 600;
}

#distance-from-user-to-santa-content-wrapper span {
  font-size: 30px;
  margin-top: 3px;
  margin-left: 3px;
}

#tracker-menu-stats-wrapper-night {
  color: #f4f4f5;
}

#distance-from-user-to-santa-icon {
  display: block;
  width: 50px;
  height: 50px;
  margin-top: 2px;
}

#distance-from-user-to-santa-night {
  background-color: rgba(0, 0, 0, .5);
  color: #5d5d5d;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  font-weight: 500;
}

#distance-from-user-to-santa-standard {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  color: #666666;
  font-weight: 500;
}

#distance-from-user-to-santa-retro {
  background-color: #dfd2ae;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  color: #666666;
  font-weight: 500;
}

#distance-from-user-to-santa-silver {
  background-color: #737372;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  color: #f7f7f7;
  font-weight: 500;
}

#distance-from-user-to-santa-dark {
  background-color: #ffffff1c;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  color: #929292;
  font-weight: 500;
}

#distance-from-user-to-santa-aubergine {
  background-color: #21556cad;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  color: #929292;
  font-weight: 500;
}

/* html {
  height: 100%;
}

body {
  background: rgba(0, 0, 0, .8);
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
} */

#santa-hat {
  height: 30px;
  margin-top: 2px;
  margin-left: 0px;
  margin-right: 0px;
}

.MapZoomWrapper {
  position: fixed;
  bottom: 0px;
  right: 5px;
  width: 45px;
  height: 105px;
  margin-bottom: 20px;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 30%);
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  -webkit-animation: slide-in-right 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-right 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

#map-zoom-wrapper-night {
  background-color: rgba(0, 0, 0, .5);
  border-color: #4d5662;
}

#map-zoom-wrapper-night span {
  color: #5d5d5d;
}

#map-zoom-wrapper-standard {
  background-color: rgba(255, 255, 255, 1);
}

#map-zoom-wrapper-standard span {
  color: #666666;
}

#map-zoom-wrapper-retro {
  background-color: #dfd2ae;
}

#map-zoom-wrapper-retro span {
  color: #666666;
}

#map-zoom-wrapper-silver {
  background-color: #737372;
}

#map-zoom-wrapper-silver span {
  color: #f7f7f7;
}

#map-zoom-wrapper-dark {
  background-color: #ffffff1c;
}

#map-zoom-wrapper-dark span {
  color: #929292;
}

#map-zoom-wrapper-aubergine {
  background-color: #21556cad;
}

#map-zoom-wrapper-aubergine span {
  color: #929292;
}

#zoom-in-btn, #zoom-out-btn {
  position: absolute;
  width: 37px;
}

#zoom-in-btn {
  top: 5px;
  left: 4px;
  height: 48px;
  border-bottom: 2px solid #666666;
}

#zoom-out-btn {
  bottom: 0px;
  left: 4px;
}

#zoom-in-btn span, #zoom-out-btn span {
  font-size: 38px;
}

.CenterMapBtnWrapper {
  position: fixed;
  bottom: 0px;
  right: 5px;
  width: 45px;
  height: 45px;
  margin-bottom: 25px;
  -webkit-animation: slide-in-right 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-right 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.CenterMapBtnWrapper span {
  font-size: 45px;
}

#center-map-btn-wrapper-night {
  background-color: rgba(0, 0, 0, .5);
  border-radius: 8px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  display: block;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.16s
}

#center-map-btn-wrapper-night span {
  color: #5d5d5d;
}

#center-map-btn-wrapper-standard {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  display: block;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.16s
}

#center-map-btn-wrapper-retro {
  background-color: #dfd2ae;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  display: block;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.16s
}

#center-map-btn-wrapper-silver {
  background-color: #737372;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  display: block;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.16s
}

#center-map-btn-wrapper-dark {
  background-color: #ffffff1c;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  display: block;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.16s
}

#center-map-btn-wrapper-aubergine {
  background-color: #21556cad;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  display: block;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.16s
}

#center-map-btn-wrapper-standard span, #center-map-btn-wrapper-retro span {
  color: #666666;
}

#center-map-btn-wrapper-silver {
  color: #f7f7f7;
}

#center-map-btn-wrapper-dark span, #center-map-btn-wrapper-aubergine span {
  color: #929292;
}

.RunShowFullScreenBtn {
  position: fixed;
  top: 60px;
  right: 10px;
  width: 40px;
  height: 40px;
  background-color: white;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}