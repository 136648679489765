.PreShow {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-image: url("https://i.ibb.co/SX7ZGFD/pre-Show-BG.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    user-select: none;
    overflow-x: hidden;
    overflow-y: hidden;
}

.preshow-info-card {
    width: 80%;
    max-width: 500px;
    height: 420px;
    position: absolute;
    left: 50%;
    top: 48%;
    -webkit-transform: translate(-50%, -48%);
    transform: translate(-50%, -48%);
    border-radius: 8px;
    z-index: 900;
    background-color: #19263c;
    box-shadow: 0px 1px 4px rgb(255 255 255 / 54%);
}

.preshow-info-card-header {
    margin-top: 10px;
    border-bottom: 1px solid #ffffff73;
}

.preshow-info-card-header p {
    margin: 0px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    text-align: center;
    /* font-family: 'Mountains of Christmas', cursive; */
    /* font-family: 'Montserrat', sans-serif; */
}

#preshow-info-card-header-p1 {
    font-size: 22px;
    color: #FFFFFF;
}

#preshow-info-card-header-p2 {
    font-size: 18px;
    color: #8d8d8d;
}

.preshow-info-card-message {
    height: calc(100% - 220px);
    overflow-y: auto;
    border-bottom: 1px solid #ffffff73;
}

.preshow-info-card-message p {
    margin: 10px;
    text-align: center;
    color: #FFFFFF;
}

.preshow-info-card-laughing-santa-wrapper {
    position: absolute;
    bottom: 70px;
    width: 100%;
    transform: scale(.5);
    margin-left: auto;
    margin-right: auto;
}

.preshow-info-card-laughing-santa-wrapper .window {
    border: 2px solid #ffffff73;
    background-color: #19263c;
    box-shadow: 0px 1px 4px rgb(255 255 255 / 54%);
}

@media only screen and (min-width: 570px) {
    .preshow-info-card-message {
        height: calc(100% - 170px);

    }
}

@media screen and (orientation:landscape) and (max-height: 570px) {
    .preshow-info-card{
        height: 230px;
        max-width: 100%;
        width: calc(100% - 150px);
        top: 40%
    }
    .preshow-info-card-header{
        margin-top: 20px;
        border: none;
    }
    #preshow-info-card-header-p1{
        font-size: 35px;
    }
    #preshow-info-card-header-p2{
        font-size: 25px;
    }
    .preshow-info-card-message{
        display: none;
    }
    .preshow-info-card-laughing-santa-wrapper{
        left: -50%;
    }
 }
