.LocationPromptCustomModalHeader {
    font-size: 30px;
    width: 100%;
    text-align: center;
}

#location-prompt-modal-night .modal-content {
    background-color: #4d5662 !important;
}

#location-prompt-modal-standard, #location-prompt-modal-silver {
    color: black;
}

#location-prompt-modal-aubergine .modal-content {
    background-color: #2c5e74 !important;
    color: #f6f6f7;
}

#location-prompt-modal-dark .modal-content {
    background-color: #434343 !important;
    color: #f6f6f7;
}

#location-prompt-custom-modal-header-night, #location-prompt-custom-modal-body-night {
    color: #f6f6f7;
}

#location-prompt-modal-retro .modal-content {
    background-color: #ece4ce !important;
}

#location-prompt-custom-modal-header-retro, #location-prompt-custom-modal-body-retro {
    color: #53583b;
}

.LocationPromtYellowParagraph{
    color: #ffc107;
}

.LocationPromtRedSpan{
    color: #dc3545;
}

.LocationPromtGreenParagraph{
    color: #28a745;
    font-weight: 600;
}