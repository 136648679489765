.FundStatus{
    position: absolute;
    height: 100%;
    width: 100%;
    overflow-y: hidden;
}

.FundStatusBody {
    position: absolute;
    top: 120px;
    left: 5%;
    width: 90%;
    max-height: calc(100% - 120px);
    /* padding-bottom: 35px; */
    background-color: #222222;
    border-radius: 5px;
    box-shadow: 3px 3px 3px #00000050;
    overflow-y: auto;
}

.FundStatusBodyHeader{
    font-size: 23px;
    text-align: center;
    text-decoration: underline;
    margin-bottom: -20px;
}

.FundStatusBodyText {
    text-align: center;
    padding: 10px;
    padding-bottom: 0px;
    /* color: #a4a4a4; */
}

.FundStatusRingWrapper {
    margin: auto;
    width: 70%;
}

#amount-needed {
    /* position: absolute;
    bottom: -15px;
    right: 5px; */
    font-size: 20px;
    color: #a4a4a4;
    text-align: right;
    margin-top: 10px;
    padding-right: 10px;
    
}

@media screen and (min-width: 350px) {
    .FundStatusBodyText{
        font-size: 18px;
    } 
}

@media screen and (min-width: 500px) {

    .FundStatusBodyHeader{
        font-size: 35px;
    }

    .FundStatusBodyText{
        font-size: 25px;
    }

    .FundStatusBody {
        position: absolute;
        top: 130px;
        left: calc(50% - 225px);
        width: 450px;
        /* padding-bottom: 40px; */
        background-color: #222222;
        border-radius: 5px;
        max-height: calc(100% - 140px);
    }
    #amount-needed {
        font-size: 23px;
    }

}