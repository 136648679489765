.ContactSection {
    position: fixed;
    height: 100vh;
    width: 100vw;
    padding-top: 50px;
    overflow-y: auto;
}

.ContactSectionHeader {
    width: 200px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
}

.ContactSectionHeader {
    width: 100%;
}

.ContactSectionHeader img{
    margin-top: -90px;
    margin-bottom: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 190px;
}


.ContactSectionInputWrapper {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    height: calc(100% - 295px);
    min-height: 115px;
    max-height: 500px;
    max-width: 600px;
}

.ContactSectionInputWrapper .form-control {
    border: 1px solid #393a3b;
    box-shadow: 2px 2px 2px rgb(0 0 0 / 30%);
}

.ContactSectionInputWrapperUpperInput {
    margin-bottom: 5px;
}

.ContactSectionInputWrapper .form-control, .ContactSectionInputWrapper .form-control:focus {
    background-color: #393a3b;
    color: azure;
}

.ContactSectionMessageWrapper .form-control {
    height: calc(100vh - 430px) !important;
    min-height: 38px;
    max-height: 400px;
}

.ContactSectionInputWrapperUpperInput {
    display: flex;
}

.ContactSectionValidField span {
    margin-top: 10px;
    margin-left: -30px;
    margin-right: 5px;
    color: #28a745;
}

.ContactSectionInvalidField {
    margin-top: 10px;
}

.ContactSectionInvalidField span {
    margin-left: -15px;
    margin-right: 5px;
    color: #dc3545;
}

.ContactSectionMessageWrapper {
    display: flex;
}

.ContactSectionSaveBtnWrapper, .ContactSectionSavingBtnWrapper {
    position: absolute;
    display: flex;
    width: 100%;
    margin-top: 15px;
    justify-content: center;
}

.ContactSectionSavingBtnWrapper {
    color: #898989;
}

.OtherContactMethods {
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: center;
}

.OtherContactMethodsSocial {
    display: flex;
    justify-content: center;
}

.OtherContactMethodsSocial img {
    height: 65px;
    margin-left: -15px;
    cursor: pointer;
    user-select: none;
}

.OtherContactMethodsEmail {
    display: flex;
    justify-content: center;
}

.OtherContactMethodsEmail span {
    font-size: 45px;
    margin-top: 10px;
    cursor: pointer;
    user-select: none;
}
