html, body {
  margin: 0px;
  padding: 0px;
  background-color: #242526;
  color: #fffffff5;
  font-family: "lucida grande", tahoma, verdana, arial, sans-serif;
}

::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  /* display: none; */
}

::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

::-webkit-scrollbar-thumb {
  /* background-image: linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,0.5)), url("https://i.ibb.co/f0gsh1R/0FYDzt.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 5px; */
  background-color: gray;
}

.PageMarkerSVG {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 40px;
}

#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  background-color: rgb(54, 54, 54);
  color: rgb(255, 255, 255);
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-11-6 21:25:41
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes rotate-in-2-fwd-ccw {
  0% {
    -webkit-transform: translateZ(-200px) rotate(45deg);
    transform: translateZ(-200px) rotate(45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) rotate(0);
    transform: translateZ(0) rotate(0);
    opacity: 1;
  }
}

@keyframes rotate-in-2-fwd-ccw {
  0% {
    -webkit-transform: translateZ(-200px) rotate(45deg);
    transform: translateZ(-200px) rotate(45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) rotate(0);
    transform: translateZ(0) rotate(0);
    opacity: 1;
  }
}

@-webkit-keyframes rotate-in-2-ccw {
  0% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotate-in-2-ccw {
  0% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@-webkit-keyframes rotate-in-center {
  0% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotate-in-center {
  0% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}