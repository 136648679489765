.AboutSection {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
}

.AboutHeader {
    width: 100%;
}


.AboutContent {
    width: 90%;
    /* max-width: 414px; */
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 15px;
    padding-left: 15px;
    max-height: calc(100% - 105px);
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
}

.AboutContent p {
    text-align: center;
    margin-top: 10px;
    /* -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; */
}

.AboutContent a {
    text-decoration: none;
    color: #FFFFFF;
    text-decoration: underline;
}

.AboutContent img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    width: 100%;
    max-width: 400px;
    /* border: 1px solid gray; */
    border-radius: 3px;
}

.JumpingElf {
    display: none;
}

.AboutSectionContentImg1 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 600px;
}

.AboutSectionContentImg1 img {
    width: 100%;
    max-width: 600px;
}

.AboutContentLowerItemHeader {
    display: flex;
    justify-content: center;
    width: 100%;
}

#about-lower-content-mistltoe {
    width: 50px;
}

.AboutContentLowerItem p {
    color: gray;
}

.AboutContentLowerItem img {
    width: 100%;
    max-width: 600px;
    max-height: 500px;
    object-fit: cover;
}

#img-credit-about-i-1 {
    font-size: 10px;
    color: gray;
    text-align: left;
    margin-top: -10px;
}

@media screen and (min-width: 360px) {
    .AboutContent {
        max-height: calc(100% - 110px);
    }
}

@media screen and (min-width: 470px) {
    .AboutContent {
        width: 80%;
    }
}

@media screen and (min-width: 600px) {
    .AboutHeader p {
        font-size: 30px;
    }

    .AboutContent {
        width: 80%;
        max-height: calc(100% - 135px);
    }

    .AboutContent p {
        font-size: 21px;
    }
}

@media screen and (min-width: 900px) {
    .AboutContent {
        width: 65%;
        /* display: flex; */
    }

    .AboutContent p {
        font-size: 20px;
    }

    .JumpingElf {
        display: block;
    }
}

#idfk {
    filter: grayscale(100%);
    max-width: 300px;

}