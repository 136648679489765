.DonateSection {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: calc(100% - 30px);
    overflow-y: hidden;
    overflow-x: hidden;
}



.DonateSectionHeader p {
    font-size: 20px;
    margin-bottom: 0px;
    /* text-decoration: underline; */
}

.FundsRaisedRingWrapper p {
    position: fixed;
    bottom: -15px;
    right: 80px;
    color: gray;
}

.FundsRaisedRingWrapper {
    height: 80px;
    width: 80px;
    position: fixed;
    right: 5px;
    bottom: 5px;

}

.DonateSectionContent {
    width: 90%;
    /* max-width: 414px; */
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 15px;
    padding-right: 15px;
    padding-left: 15px;
    max-height: calc(100% - 200px);
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
}

.DonateSectionContent p {
    text-align: center;
    margin-top: 10px;
    /* -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; */
}

.DonateSectionDonateBtn {
    width: 100%;
    margin-top: 15px;
    z-index: 1000;
}

#donate-section-donate-btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.JumpingElf {
    transform: scale(.3);
    left: 0px;
    bottom: -150px;
}

.DonateSectionContentImg1 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 600px;
}

.DonateSectionContentImg1 img {
    width: 100%;
    max-width: 600px;
}

#img-credit-donate-i-1 {
    font-size: 10px;
    color: gray;
    text-align: left;
    margin: 0px;
}

.DonateSectionDonateForm {
    margin-bottom: 10px;
}

@media screen and (min-width: 470px) {
    .DonateSectionContent {
        width: 80%;
    }
}

@media screen and (min-width: 600px) {
    .DonateSectionHeader p {
        font-size: 30px;
    }

    .DonateSectionContent {
        width: 70%;
        max-height: calc(100% - 170px);
    }

    .DonateSectionContent p {
        font-size: 21px;
    }
}

@media screen and (min-width: 900px) {
    .DonateSectionContent {
        width: 70%;
        max-height: calc(100% - 180px);
    }

    .JumpingElf {
        display: block;
    }

    .FundsRaisedRingWrapper {
        height: 100px;
        width: 100px;
    }

    .FundsRaisedRingWrapper p {
        position: fixed;
        bottom: -15px;
        right: 100px;
    }
}