.SponsorCarousel{
    position: fixed;
    bottom: -4px;
    left: 5px;
    margin-bottom: 30px;
}

.SponsorCarouselItem{
    /* width: 100%;
    display: flex;
    justify-content: center; */
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 30%)
}

.SponsorCarouselItem img{
    height: 70px;
    max-width: 180px;
    object-fit: contain;
}

#sponsor-carousel-item-standard{
    background-color: #ffffff;
}

#sponsor-carousel-item-retro{
    background-color: #ded1ad;
}

#sponsor-carousel-item-silver{
    background-color: #737372;
}

#sponsor-carousel-item-dark{
    background-color: #ffffff1c;
}

#sponsor-carousel-item-night{
    background-color: #00000080;
}

#sponsor-carousel-item-aubergine{
    background-color: #21556cad;
}

