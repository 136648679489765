.NavMenuBtn {
    width: 50px;
    height: 50px;
    text-align: center;
    position: fixed;
    top: 5px;
    right: 5px;
    cursor: pointer;
    user-select: none;
    background-color: rgba(0, 0, 0);
    color: #666666;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 30%);
    border-radius: 8px;
}

.NavMenuBtn span {
    font-size: 40px;
    margin-top: 5px;
}

.NavMenuContent {
    position: fixed;
    top: 3px;
    right: 3px;
    width: 180px;
    z-index: 1000;
    user-select: none;
    background-color: #18191a;
    color: #666666;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 30%);
    border-bottom-left-radius: 5px;
    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.NavMenuFooter {
    position: absolute;
    display: flex;
    height: 30px;
    width: 100%;
    bottom: 8px;
}

#nav-menu-close-btn {
    color: #FFFFFF;
    font-size: 30px;
    margin-left: auto;
    margin-right: 8px;
    cursor: pointer;
    user-select: none;
}

.NavMenuRoutes {
    padding: 3px;
    padding-bottom: 0px;
}

.NavMenuRoute {
    height: 65px;
    margin-bottom: 3px;
    border-radius: 5px;
    background-color: #232425;
}

.NavMenuRouteBtn {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    text-decoration: none;
    user-select: none;
    color: #FFFFFF;
}

.NavMenuRouteBtn p {
    margin-top: 15px;
    margin-left: 20px;
    font-size: 20px;
}

.NavMenuRouteSVG, .NavMenuRouteBtn img {
    position: absolute;
    left: 10px;
    width: 40px;
}

.NavMenuRouteSVG{
    margin-top: 13px;
}

.NavMenuRouteBtn img{
    margin-top: 10px;
}

.NavMenuLaughingSantaWrapper{
    transform: scale(.2);
    position: absolute;
    left: 12px;
    margin-top: 5px;
    width: 40px;
}

#tracker-route-text{
    margin-left: 55px;
}

#funding-route-text{
    margin-right: -20px;
}