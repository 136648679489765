.SponsorsSection {
    position: absolute;
    top: 0px;
    left: 10px;
    height: 100%;
    width: calc(100% - 20px);
    overflow-y: hidden;
}

.SponsorListYearWrapper {
    position: absolute;
    margin-top: -50px;
    display: flex;
    margin-bottom: 10px;
}

.SponsorListYearItem {
    margin-right: 8px;
    height: 28px;
    width: 50px;
    line-height: 18px;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 2px 2px 2px rgb(0 0 0 / 30%);
    background-color: #3a3b3c;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    user-select: none;
}

.SponsorListYearItemChecked {
    border-bottom: 3px solid #007bff;
}

.SposorSectionHeader {
    margin-bottom: 10px;
}

.SponsorList {
    max-height: calc(100% - 95px);
    overflow-y: auto;
    padding: 15px;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    overflow-x: hidden;
}

.SponsorItem {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    margin-bottom: 5px;
    border-radius: 5px;
    box-shadow: 2px 2px 2px rgb(0 0 0 / 30%);
    background-color: #3a3b3c78;
    color: #FFFFFF;
}

.SponsorItemHeader {
    /* height: 50px; */
    text-align: center;
    padding-top: 5px;
}

.SponsorItemHeader p {
    font-size: 20px;
    margin: 0px;
}

.SponsorLogoWrapper {
    margin-top: 5px;
}

.SponsorLogoWrapper img {
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.SponsorFooter {
    display: flex;
    justify-content: space-between;
    height: 50px;
}

.SponsorFooterItem {
    height: 50px;
    line-height: 50px;
    overflow: hidden;
}

.SponsorFooter a {
    color: gray;
    margin-left: 10px;
    /* margin-top: 23px; */
}

.SponsorFooter span {
    font-size: 45px;
    margin: 0px;
    margin-top: 10px;
    margin-left: 10px;
    cursor: pointer;
    user-select: none;
}

.SponsorFooter img {
    height: 70px;
    margin-top: -10px;
    margin-right: -5px;
    cursor: pointer;
    user-select: none;
}

.SponsorFooterItem a {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    margin-top: 10px;
}

#foo-fml {
    /*************************wtf!!!********************/
    filter: brightness(0) invert(1);
}

#efdc-fc-f-e-cb .SponsorLogoWrapper {
    margin-top: 20px;
    margin-bottom: -15px;
    transform: scale(.85);
}

#efdc-fc-f-e-cb .SponsorItemHeader {
    height: 35px;
}

@media screen and (min-width: 800px) {
    .SponsorList {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-column-gap: 5px;
        width: 800px;
        margin-left: auto;
        margin-right: auto;
        padding-right: 20px;
    }

    .SponsorsHeader {
        height: 65px;
    }

    .SponsorsHeader p {
        margin-top: 0px;
        font-size: 30px;
    }

    .SponsorItem {
        width: 100%;
        height: 200px;
    }

    .SponsorLogoWrapper {
        height: 110px;
    }

    .SponsorLogoWrapper img {
        height: 100%;
    }

    #ccf-b--bac-cceb .SponsorLogoWrapper {
        height: 100px;
        margin-bottom: -20px;
    }
}

@media screen and (min-width: 1200px) {
    .SponsorList {
        display: grid;
        grid-template-columns: 33.33% 33.33% 33.33%;
        grid-column-gap: 5px;
        width: 1200px;
        margin-left: auto;
        margin-right: auto;
    }

    .SponsorItem {
        width: 100%;
    }
}